import { enableProdMode } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from '@configuration/app.module';
import { environment } from '@environment/environment';
import { throwError } from 'rxjs';

Sentry.init({
  dsn: environment.SENTRY.DNS,
  beforeSend: (event) => {
    if (environment.ENVIRONMENT === 'development') {
      return null;
    }
    return event;
  },
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration()
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: environment.SENTRY.TRACE_URL,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: environment.ENVIRONMENT,
  initialScope: (scope) => scope.setTag('app_name', 'configuration')
});

if (environment.IS_PRODUCTION) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err: Error) => throwError(err));
